const MahindraUserForm = () => import("./pages/MahindraVista.vue");

export const MahindraVistaRoutes = [
  {
    path: "/mahindra-vista",
    component: MahindraUserForm,
    meta: {
      title: "Mahindra Vista"
    }
  }
];
